// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-knjiga-js": () => import("./../../../src/pages/knjiga.js" /* webpackChunkName: "component---src-pages-knjiga-js" */),
  "component---src-pages-mnenja-js": () => import("./../../../src/pages/mnenja.js" /* webpackChunkName: "component---src-pages-mnenja-js" */),
  "component---src-pages-nakup-knjige-js": () => import("./../../../src/pages/nakup-knjige.js" /* webpackChunkName: "component---src-pages-nakup-knjige-js" */),
  "component---src-pages-narocilo-oddano-js": () => import("./../../../src/pages/narocilo-oddano.js" /* webpackChunkName: "component---src-pages-narocilo-oddano-js" */),
  "component---src-pages-piskotki-js": () => import("./../../../src/pages/piskotki.js" /* webpackChunkName: "component---src-pages-piskotki-js" */),
  "component---src-pages-predavanja-js": () => import("./../../../src/pages/predavanja.js" /* webpackChunkName: "component---src-pages-predavanja-js" */),
  "component---src-pages-splosni-pogoji-poslovanja-js": () => import("./../../../src/pages/splosni-pogoji-poslovanja.js" /* webpackChunkName: "component---src-pages-splosni-pogoji-poslovanja-js" */)
}

